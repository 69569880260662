import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import { Switch } from 'antd';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import './styles.scss';
import { Select, MenuItem, OutlinedInput, FormControl } from '@mui/material';

// Selectors
import { selectConfigRecords } from 'services/Config/selectors';

//Thunks
import {
    isImperial,
    i18nVolumeUnit,
    i18nVolumeLitersUnit,
    i18nDistanceUnit,
    i18nMilesToKm,
} from 'services/Units/thunks';
import { setEcoCopy } from 'services/Config/thunks';
import { RampLeft } from '@mui/icons-material';

const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #4f9c20;
        border-color: #4f9c20;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: rgba(255, 255, 255, 0.69);
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #4f9c20;
    }
`;

function EconomieConfigComponent(props) {
    const [detailed, setDetailed] = useState(true);
    // const [selectedTimeStart, setSelectedTimeStart] = useState('07:00');
    // const [selectedTimeEnd, setSelectedTimeEnd] = useState('07:00');
    // const [fuelType, setFuelType] = useState('gasoline');
    // const [checkRalProlong, setCheckRalProlong] = useState(false);
    // const [ralProlong, setRalProlong] = useState(60);
    // const [ralProlongError, setRalProlongError] = useState(false);
    // const [checkEngineCutoff, setCheckEngineCutoff] = useState(false);
    // const [litersPerHour, setLitersPerHour] = useState(0);
    const [buttonChecked, setButtonChecked] = useState(false);
    // const [horraireValue, setHorraireValue] = useState(0);
    // const [checkSpeedDetection, setCheckSpeedDetection] = useState(false);
    // const [maximumSpeed, setMaximumSpeed] = useState(0);
    // const [preAlertSpeed, setPreAlertSpeed] = useState(0);
    // const [preAlertSpeedCheck, setPreAlertSpeedCheck] = useState(false);
    const handleHorraireValue = (e) => {
        props.handleHorraireValue(e);
        // setHorraireValue(e.target.value);
    };
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            const hourString = hour < 10 ? `0${hour}` : `${hour}`;
            options.push(`${hourString}:00`);
        }
        return options;
    };
    const handlefuelTypeChange = (event) => {
        props.handlefuelTypeChange(event);
        // setFuelType(event.target.value);
    };
    const handleTimeChangeStart = (event) => {
        props.handleTimeChangeStart(event);
        // setSelectedTimeStart(event.target.value);
    };
    const handleTimeChangeEnd = (event) => {
        props.handleTimeChangeEnd(event);
        // setSelectedTimeEnd(event.target.value);
    };
    useEffect(
        () => {
            if (props.checked) {
                setButtonChecked(true);
                props.setEcoCopy(true);
            } else {
                setButtonChecked(false);
                props.setEcoCopy(false);
            }
        },
        [props.checked]
    );
    // useEffect(
    //     () => {
    //         if (ralProlongError) {
    //             setTimeout(() => {
    //                 setRalProlongError(false);
    //             }, 3000);
    //         }
    //     },
    //     [ralProlongError]
    // );
    // useEffect(
    //     () => {
    //         if (props.configRecords.size === 0) return;
    //         const speedDetection = this.props.configRecords.get('speedDetection');
    //         const preAlertMaximumSpeed = speedDetection.get('preAlertMaximumSpeed');
    //         const additionalData = props.configRecords.get('additionalData');
    //         const engineCutoff = props.configRecords.get('engineCutoff');
    //         let maximumSpeed = speedDetection.get('maximumSpeed');

    //         const checkSpeedDetection = speedDetection.get('isSpeedThresholdActivated');
    //         (maximumSpeed =
    //             maximumSpeed >= 0 && maximumSpeed < 1000
    //                 ? Math.floor(this.props.i18nDistanceUnit(maximumSpeed))
    //                 : 0),
    //             setMaximumSpeed(maximumSpeed);
    //         setCheckSpeedDetection(checkSpeedDetection);
    //         setCheckRalProlong(engineCutoff.get('activeIdleEvent'));
    //         setFuelType(additionalData.get('fuel_type') || 'gasoline');
    //         setCheckEngineCutoff(engineCutoff.get('cutoffCutEngine'));
    //         setLitersPerHour(
    //             props.i18nVolumeUnit(additionalData.get('liters_per_hour')) ||
    //                 (props.isImperial() ? 1 : 4)
    //         );
    //         setPreAlertSpeed(preAlertMaximumSpeed);
    //         setPreAlertSpeedCheck(speedDetection.get('isPreAlertSpeedActivated'));
    //     },
    //     [props.configRecords]
    // );
    const handleEcoChange = (e) => {
        if (e.target.checked) {
            props.setEcoCopy(true);
            setButtonChecked(true);
        } else {
            props.setEcoCopy(false);
            setButtonChecked(false);
        }
    };
    const handleLitersChange = (e) => {
        props.handleLitersChange(e);
        // setLitersPerHour(e.target.value);
    };
    const handleCheckRalProlong = (e) => {
        props.handleCheckRalProlong(e);
        // setCheckRalProlong(e);
    };
    const handleRalProlong = (e) => {
        props.handleRalProlong(e);
        // if (e.target.value < 60 && e.target.value > 9999) {
        //     setRalProlongError(true);
        //     return;
        // }
        // setRalProlong(e.target.value);
    };
    const handleMaximumSpeed = (e) => {
        props.handleMaximumSpeed(e);
        // setMaximumSpeed(e.target.value);
    };
    const handlePreAlertSpeed = (e) => {
        props.handlePreAlertSpeed(e);
        // setPreAlertSpeed(e.target.value);
    };
    const handleCheckSpeedDetection = (e) => {
        props.handleCheckSpeedDetection(e);
        // setCheckSpeedDetection(e);
    };
    const handleCheckPreAlertSpeed = (e) => {
        // if(!checkSpeedDetection) return;
        props.handleCheckPreAlertSpeed(e);
        // setPreAlertSpeedCheck(e);
    }
    return (
        <Grid
            container
            sx={{
                height: 'fit-content',
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                borderRadius: '7px',
                marginBottom: '0.7em',
            }}
        >
            <Grid xs={8}>
                {props.step === 1 && (
                    <CustomCheckbox checked={buttonChecked} onChange={(e) => handleEcoChange(e)} />
                )}
                <span className={'economieHeader'}> Économies d'essence</span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid xs={12}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>
            {detailed ? (
                <Grid container>
                    <Grid item xs={6} sx={{ padding: '0 10px' }}>
                        <span>Type de Carburant</span>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            sx={{
                                m: 0,
                            }}
                        >
                            <Select
                                sx={{
                                    width: '70%',
                                    height: '40%',
                                }}
                                value={props.fuelType}
                                onChange={handlefuelTypeChange}
                            >
                                <MenuItem
                                    key={1}
                                    value={'gasoline'}
                                    sx={{
                                        fontSize: '12px',
                                        fontColor: '#64748B',
                                        margin: '0',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontColor: '#64748B',
                                        }}
                                    >
                                        Essence
                                    </span>
                                </MenuItem>
                                <MenuItem
                                    key={2}
                                    value={'diesel'}
                                    sx={{
                                        fontSize: '12px',
                                        fontColor: '#64748B',
                                        margin: '0',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontColor: '#64748B',
                                        }}
                                    >
                                        Diesel
                                    </span>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            {props.checkEngineCutoff ? (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginBottom: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Switch checked={props.checkEngineCutoff} />

                                    <span style={{ marginLeft: '10px' }}>Coupe moteur</span>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginBottom: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Switch
                                        onChange={(e) => {
                                            handleCheckRalProlong(e);
                                        }}
                                        checked={props.checkRalProlong}
                                    />
                                    {props.checkRalProlong && (
                                        <OutlinedInput
                                            type="number"
                                            sx={{
                                                backgroundColor: '#FFFFFF',
                                                borderColor: '#CCCCCC',
                                                borderRadius: '8px',
                                                width: '70%',
                                                height: '40%',
                                                input: {
                                                    padding: '10px',
                                                },
                                            }}
                                            id="outlined-fuel-quantity"
                                            aria-describedby="outlined-fuel-quantity"
                                            value={props.ralProlong}
                                            onChange={(e) => {
                                                handleRalProlong(e);
                                            }}
                                        />
                                    )}
                                    {props.ralProlongError && (
                                        <span style={{ color: 'red' }}>
                                            La valeur doit être comprise entre 60 et 9999
                                        </span>
                                    )}
                                    <span style={{ marginLeft: '10px' }}>Ralenti prolongé</span>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{ padding: '0 10px', display: 'flex', flexDirection: 'column' }}
                    >
                        <span>Litres par heure au ralenti</span>
                        <OutlinedInput
                            type="number"
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderColor: '#CCCCCC',
                                borderRadius: '8px',
                                width: '70%',
                                height: '40%',
                                input: {
                                    padding: '10px',
                                },
                            }}
                            id="outlined-fuel-quantity"
                            aria-describedby="outlined-fuel-quantity"
                            value={props.litersPerHour}
                            onChange={handleLitersChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid xs={6}>
                                <CustomCheckbox>Horaire</CustomCheckbox>
                            </Grid>
                            <Grid xs={6}>
                                <OutlinedInput
                                    type="number"
                                    sx={{
                                        backgroundColor: '#FFFFFF',
                                        borderColor: '#CCCCCC',
                                        borderRadius: '8px',
                                        width: '70%',
                                        height: '50%',
                                        input: {
                                            padding: '10px',
                                        },
                                    }}
                                    id="outlined-horraire"
                                    aria-describedby="outlined-horraire"
                                    value={props.horraireValue}
                                    onChange={(e) => {
                                        handleHorraireValue(e);
                                    }}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <Select
                                        sx={{ width: '75px', height: '25px' }}
                                        value={props.selectedTimeStart}
                                        onChange={handleTimeChangeStart}
                                    >
                                        {generateTimeOptions().map((time, index) => (
                                            <MenuItem
                                                key={index}
                                                value={time}
                                                sx={{
                                                    fontSize: '10px',
                                                    fontColor: '#64748B',
                                                    margin: '0',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                        fontColor: '#64748B',
                                                    }}
                                                >
                                                    {time}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={6}>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <Select
                                        sx={{ width: '75px', height: '25px' }}
                                        value={props.selectedTimeEnd}
                                        onChange={handleTimeChangeEnd}
                                    >
                                        {generateTimeOptions().map((time, index) => (
                                            <MenuItem
                                                key={index}
                                                value={time}
                                                sx={{
                                                    fontSize: '10px',
                                                    fontColor: '#64748B',
                                                    margin: '0',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                        fontColor: '#64748B',
                                                    }}
                                                >
                                                    {time}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            marginLeft: '15px',
                            marginBottom: '15px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        xs={12}
                    >
                        <Switch
                            defaultChecked
                            checked={props.checkSpeedDetection}
                            onChange={(e) => {
                                handleCheckSpeedDetection(e);
                            }}
                        />
                        <span style={{ marginLeft: '10px' ,marginRight:'10px'}}>Limite de vitesse</span>
                        {props.checkSpeedDetection && (
                            <OutlinedInput
                                type="number"
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    borderColor: '#CCCCCC',
                                    borderRadius: '8px',
                                    width: '5vw',
                                    height: '40%',
                                    input: {
                                        padding: '10px',
                                    },
                                }}
                                id="outlined-maximum-speed"
                                aria-describedby="outlined-maximum-speed"
                                value={props.maximumSpeed}
                                onChange={(e) => {
                                    handleMaximumSpeed(e);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        sx={{
                            marginLeft: '15px',
                            marginBottom: '15px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Switch
                            defaultChecked
                            checked={props.preAlertSpeedCheck}
                            onChange={(e) => {
                                handleCheckPreAlertSpeed(e);
                            }}
                        />

                        <span style={{ marginLeft: '10px',marginRight:'10px' }}>Pré-alerte limite de vitesse</span>
                        {props.preAlertSpeedCheck && (
                            <OutlinedInput
                                type="number"
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    borderColor: '#CCCCCC',
                                    borderRadius: '8px',
                                    width: '5vw',
                                    height: '40%',
                                    input: {
                                        padding: '10px',
                                    },
                                }}
                                id="outlined-preAlert-speed"
                                aria-describedby="outlined-preAlert-speed"
                                value={props.preAlertSpeed}
                                onChange={(e) => {
                                    handlePreAlertSpeed(e);
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
}
// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configRecords: selectConfigRecords(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            isImperial,
            i18nVolumeUnit,
            i18nVolumeLitersUnit,
            i18nDistanceUnit,
            i18nMilesToKm,
            setEcoCopy,
        },
        dispatch
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EconomieConfigComponent));
