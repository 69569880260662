import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { CopyIcon } from '../../components/Icons';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { selectVehicles, selectEventTypes } from '../../services/Vehicle/selectors';
import { selectCopyTargetVehicles, selectVehicleToCopy } from '../../services/Config/selectors';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import { Checkbox } from 'antd';
import { getStatus, getAuxStatus, getCustomIcon } from 'helpers';
import './styles.scss';
import VehicleIconDisplay from '../VehicleIconDisplay';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { fromJS } from 'immutable';
//Thunks
import { getVehicles } from '../../services/Vehicle/thunks';
import {
    getUnauthorizedMoveConfigByEquipementId,
    setSelectVehicle,
    addTargetVehicle,
    removeTargetVehicle,
    setVehiculeRefs,
} from 'services/Config/thunks';
import { CheckBox } from '@mui/icons-material';

function VehiculeListConfig(props) {
    const [vehiculeList, setVehiculeList] = useState([]);
    const checkboxRefs = useRef({});
    const [allVehicles, setAllVehicles] = useState(false);
    const [checkboxState, setCheckboxState] = useState({});
    const firstVehiculeListChange = useRef(true); // to only set the first vehicle as selected on the first render
    useEffect(() => {
        props.getVehicles();
    }, []);
    useEffect(
        () => {
            console.log(props.vehicles.toJS());
            setVehiculeList(props.vehicles.filter((veh) => getModuleType(veh) === 'ORCA'));
        },
        [props.vehicles]
    );
    useEffect(
        () => {
            setVehiculeRefs(checkboxRefs);
            if (props.step === 0) {
                const updatedState = {};
                Object.keys(checkboxRefs.current).forEach((key) => {
                    updatedState[key] = key === props.vehicleToCopy.vehicleId.toString();
                });
                setCheckboxState(updatedState);
            }
        },
        [props.step]
    );
    useEffect(
        () => {
            if (vehiculeList.size > 0 && props.step === 0 && firstVehiculeListChange.current) {
                firstVehiculeListChange.current = false;
                props.setSelectVehicle(vehiculeList.toJS()[0].id, vehiculeList.toJS()[0].name);
                let updatedState = {};
                updatedState[vehiculeList.toJS()[0].id.toString()] = true;
                setCheckboxState(updatedState);
            }
        },
        [vehiculeList]
    );
    const getModuleType = (vehicle) => {
        const vehicleMap = fromJS(vehicle);
        const additionalData = vehicleMap.get('additionalData');
        let moduleType = '';
        const cardNumber = vehicleMap.get('cardNumber');
        if (cardNumber) {
            if (
                cardNumber.startsWith('R2') ||
                cardNumber.startsWith('R3') ||
                cardNumber.startsWith('R4') ||
                cardNumber.startsWith('G3') ||
                cardNumber.startsWith('OR') ||
                cardNumber.startsWith('AP') ||
                cardNumber.startsWith('LM') ||
                cardNumber.startsWith('HS') ||
                cardNumber.startsWith('MN')
            ) {
                moduleType = 'ORCA';
            } else if (
                cardNumber.startsWith('87') ||
                cardNumber.startsWith('88') ||
                cardNumber.startsWith('68')
            ) {
                moduleType = 'GEOMETRIS';
            } else if (cardNumber.startsWith('0-')) {
                moduleType = 'GLOBALSTAR';
            } else {
                return '';
            }
        }
        if (!moduleType) {
            if (additionalData && additionalData.toJS().moduleType) {
                moduleType =
                    additionalData.toJS().moduleType !== undefined
                        ? additionalData.toJS().moduleType
                        : '';
            }
        }
        return moduleType.toUpperCase();
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        const filteredVehicles = props.vehicles.filter((veh) =>
            veh
                .get('name')
                .toLowerCase()
                .includes(query)
        );
        setVehiculeList(filteredVehicles);
    };
    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setAllVehicles(true);
            vehiculeList.forEach((veh) => {
                props.addTargetVehicle(veh.get('id'), veh.get('name'));
            });
        } else {
            setAllVehicles(false);
            vehiculeList.forEach((veh) => {
                props.removeTargetVehicle(veh.get('id'));
            });
        }
    };
    const handleChange = (e, veh) => {
        let updatedState = { ...checkboxState };
        if (e.target.checked) {
            if (props.step === 0) {
                props.setSelectVehicle(veh.get('id'), veh.get('name'));
                Object.keys(checkboxRefs.current).forEach((key) => {
                    checkboxRefs.current[key].state.checked = key === veh.get('id').toString();
                    updatedState[key] = key === veh.get('id').toString();
                });
            } else {
                updatedState[veh.get('id').toString()] = true;
                if (
                    !props.targetVehicules.toJS().find((item) => item.vehicleId === veh.get('id'))
                ) {
                    props.addTargetVehicle(veh.get('id'), veh.get('name'));
                }
            }
        } else {
            updatedState[veh.get('id').toString()] = false;
            if (props.step === 0) {
                props.setSelectVehicle(0, '');
                Object.keys(checkboxRefs.current).forEach((key) => {
                    checkboxRefs.current[key].state.checked = key === veh.get('id').toString();
                });
            } else {
                props.removeTargetVehicle(veh.get('id'));
            }
        }
        setCheckboxState(updatedState);
    };
    return (
        <Grid container className="vehicleContainer">
            <Grid container xs={12} style={{ alignItems: 'center' }}>
                <Grid xs={6}>
                    <div
                        style={{
                            fontSize: '18px',
                            fontFamily: 'Inter',
                            fontWeight: '1000',
                            margin: '10px 0 5px 10px',
                        }}
                    >
                        Select Vehicle
                    </div>
                </Grid>
                {props.step === 0 && (
                    <Grid xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            startIcon={<CopyIcon />}
                            sx={{
                                height: 'fit-content',
                                textTransform: 'none',
                                backgroundColor: '#4F9C20',
                                color: 'white',
                                borderColor: '#4F9C20',
                                '&:hover': {
                                    backgroundColor: '#3E7C16',
                                    borderColor: '#3E7C16',
                                },
                            }}
                            onClick={props.onClickCopy}
                            disabled={props.vehicleToCopy.vehicleId === 0}
                        >
                            <span>Copier Configuration</span>
                        </Button>
                    </Grid>
                )}
            </Grid>

            <Grid xs={12}>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        label="outlined-adornment-search"
                        sx={{ height: '35px' }}
                        onChange={handleSearchChange}
                    />
                </FormControl>
            </Grid>

            {props.step === 1 && (
                <Grid
                    xs={12}
                    className="vehiculeItem"
                    style={{
                        fontSize: '14px',
                        fontFamily: 'inter',
                        paddingBottom: '20px',
                        fontWeight: '900',
                        color: '#101828',
                        paddingLeft: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Checkbox checked={allVehicles} onChange={(e) => handleSelectAllChange(e)} />
                    <span style={{ marginLeft: '10px' }}>Select All</span>
                </Grid>
            )}
            <Grid
                xs={12}
                container
                style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: '86vh',
                }}
            >
                {(vehiculeList.size > 0 && props.step === 1
                    ? vehiculeList.filter((veh) => veh.get('id') !== props.vehicleToCopy.vehicleId)
                    : vehiculeList
                ).map((veh) => {
                    const auxArray = new Array(4).fill(0);

                    if (veh.getIn(['latestRealTime', 'sensorAux'])) {
                        auxArray[0] = 1;
                    }
                    if (veh.getIn(['latestRealTime', 'sensorAux2'])) {
                        auxArray[1] = 1;
                    }
                    if (veh.getIn(['latestRealTime', 'sensorAux3'])) {
                        auxArray[2] = 1;
                    }
                    if (veh.getIn(['latestRealTime', 'sensorAux4'])) {
                        auxArray[3] = 1;
                    }

                    const isKey = veh.getIn(['latestRealTime', 'sensorKey']);
                    const status = veh.get('status')
                        ? getStatus(
                              veh,
                              veh.getIn(['status', 'eventTypeId']),
                              props.eventTypes,
                              true,
                              isKey
                          )
                        : null;

                    const isAux =
                        veh.getIn(['latestRealTime', 'sensorAux']) ||
                        veh.getIn(['latestRealTime', 'sensorAux2']) ||
                        veh.getIn(['latestRealTime', 'sensorAux3']) ||
                        veh.getIn(['latestRealTime', 'sensorAux4']);

                    const auxStatus = getAuxStatus(auxArray);

                    return (
                        <Grid
                            key={veh.get('id')}
                            xs={12}
                            md="12"
                            className="vehiculeItem"
                            style={{
                                fontSize: '14px',
                                fontFamily: 'inter',
                                border: 'solid 1px #EAECF0',
                                paddingBottom: '20px',
                                paddingTop: '20px',
                                fontWeight: '900',
                                color: '#101828',
                                paddingLeft: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                            onClick={() => {
                                props.onClickConfig(veh.get('id'));
                                props.getUnauthorizedMoveConfigByEquipementId(veh.get('id'));
                                let updatedCheckState = { ...checkboxState };
                                if (props.step === 0) {
                                    if (!checkboxState[veh.get('id')]) {
                                        props.setSelectVehicle(veh.get('id'), veh.get('name'));
                                        updatedCheckState[veh.get('id')] = true;
                                        Object.keys(updatedCheckState).forEach((key) => {
                                            updatedCheckState[key] =
                                                key === veh.get('id').toString();
                                        });
                                    } else {
                                        props.setSelectVehicle(0, '');
                                        updatedCheckState[veh.get('id')] = false;
                                    }
                                } else {
                                    if (!checkboxState[veh.get('id')]) {
                                        if (
                                            !props.targetVehicules
                                                .toJS()
                                                .find((item) => item.vehicleId === veh.get('id'))
                                        ) {
                                            props.addTargetVehicle(veh.get('id'), veh.get('name'));
                                            updatedCheckState[veh.get('id')] = true;
                                        }
                                    } else {
                                        updatedCheckState[veh.get('id')] = false;
                                        props.removeTargetVehicle(veh.get('id'));
                                    }
                                }
                                setCheckboxState(updatedCheckState);
                            }}
                        >
                            <Checkbox
                                checked={
                                    props.step === 0
                                        ? checkboxState[veh.get('id')]
                                        : allVehicles || checkboxState[veh.get('id')]
                                }
                                ref={(el) => (checkboxRefs.current[veh.get('id')] = el)}
                                onChange={(e) => handleChange(e, veh)}
                            />
                            <div style={{ marginLeft: '10px' }}>{veh.get('name')}</div>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}

const mapStateToProps: Object = createStructuredSelector({
    vehicles: selectVehicles(),
    eventTypes: selectEventTypes(),
    targetVehicules: selectCopyTargetVehicles(),
    vehicleToCopy: selectVehicleToCopy(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getVehicles,
            getUnauthorizedMoveConfigByEquipementId,
            setSelectVehicle,
            addTargetVehicle,
            removeTargetVehicle,
            setVehiculeRefs,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(VehiculeListConfig))
);
