// @flow

import { get } from 'immutable';
import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectConfigState = () => (state) => state.get('config');

const selectConfigRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('ConfigRecords'));

const selectSettingsRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('SettingsRecords'));

const selectModuleRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('ModuleRecords'));

const selectEmailNotifConfigRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('EmailNotifConfigRecords'));

const selectEmailsRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('Emails'));

const selectUnauthorizedMoveRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('UnauthorizedMoveRecords'));

const selectConfigLoading = () =>
    createSelector(selectConfigState(), (substate) => substate.get('loading'));

const selectEmailsLoading = () =>
    createSelector(selectConfigState(), (substate) => substate.get('loadingEmails'));

const selectConfigError = () =>
    createSelector(selectConfigState(), (substate) => substate.get('configError'));

const selectEngineConfigActivation = () =>
    createSelector(selectConfigState(), (substate) => substate.get('globalActivation'));

const selectEngineConfigConditions = () =>
    createSelector(selectConfigState(), (substate) => substate.get('engineConditions'));

const selectEngineConfigConditionToEdit = () =>
    createSelector(selectConfigState(), (substate) => substate.get('conditionToEdit'));
const selectEngineConfigNewCondition = () =>
    createSelector(selectConfigState(), (substate) => substate.get('newCondition'));
const selectEngineConfigDelays = () =>
    createSelector(selectConfigState(), (substate) => substate.get('engineConditionsDelays'));
const selectConfigCopyEco = () =>
    createSelector(selectConfigState(), (substate) => substate.get('copySettingsEco'));
const selectConfigCopyNotif = () =>
    createSelector(selectConfigState(), (substate) => substate.get('copySettingsNotif'));
const selectConfigCopyUnauth = () =>
    createSelector(selectConfigState(), (substate) => substate.get('copySettingsUnauth'));
const selectConfigCopyLpm = () =>
    createSelector(selectConfigState(), (substate) => substate.get('copySettingsLpm'));
const selectConfigCopyAccel = () =>
    createSelector(selectConfigState(), (substate) => substate.get('copySettingsAccel'));
const selectCopyTargetVehicles = () =>
    createSelector(selectConfigState(), (substate) => substate.get('targetVehicles'));
const selectVehicleToCopy = () =>
    createSelector(selectConfigState(), (substate) => substate.get('vehicleToCopy'));
const selectVehiculeRefs = () =>
    createSelector (selectConfigState(), (substate) => substate.get('vehiculeRefs'))
const selectConfigCopyError = () =>
    createSelector (selectConfigState(), (substate) => substate.get('copyConfigError'))
export {
    selectConfigRecords,
    selectModuleRecords,
    selectSettingsRecords,
    selectConfigLoading,
    selectEmailsRecords,
    selectEmailNotifConfigRecords,
    selectUnauthorizedMoveRecords,
    selectEmailsLoading,
    selectConfigError,
    selectEngineConfigActivation,
    selectEngineConfigConditions,
    selectEngineConfigConditionToEdit,
    selectEngineConfigNewCondition,
    selectEngineConfigDelays,
    selectConfigCopyEco,
    selectConfigCopyNotif,
    selectConfigCopyUnauth,
    selectConfigCopyLpm,
    selectConfigCopyAccel,
    selectCopyTargetVehicles,
    selectVehicleToCopy,
    selectVehiculeRefs,
    selectConfigCopyError,
};
