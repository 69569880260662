import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ApReservationStepIcon, ApStepperSeperator } from '../../components/Icons';
import VehiculeListConfig from '../../components/VehiculeListConfig';
import NotificationConfig from '../../components/NotificationConfig';
import EconomieConfigComponent from '../../components/EconomieConfigComponent';
import UnauthorizedMoveConfigBox from '../../components/UnauthorizedMoveConfigBox';
import LowPowerModeSettings from '../../components/LowPowerModeSettings';
import AccelerometreSettings from '../../components/AccelerometreSettings';
import FinishCopyComponent from '../../components/FinishCopyComponent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Checkbox, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './styles.scss';
import {
    getConfigByEquipementId,
    getEmailNotifConfigByEquipementId,
    copyConfiguration,
} from 'services/Config/thunks';
import { i18nDistanceUnit, i18nVolumeUnit } from 'services/Units/thunks';

import {
    selectConfigCopyNotif,
    selectConfigCopyEco,
    selectConfigCopyUnauth,
    selectConfigCopyLpm,
    selectConfigCopyAccel,
    selectCopyTargetVehicles,
    selectVehicleToCopy,
    selectVehiculeRefs,
    selectConfigCopyError,
    selectConfigRecords,
    selectUnauthorizedMoveRecords,
} from '../../services/Config/selectors';
import colors from 'styles/colors';
const defaultdate = '2019-04-';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function Configuration(props) {
    const dateCalendarAdapter = (day) => {
            switch (day) {
                case 'Sunday':
                    return '07';
                case 'Monday':
                    return '08';
                case 'Tuesday':
                    return '09';
                case 'Wednesday':
                    return '10';
                case 'Thursday':
                    return '11';
                case 'Friday':
                    return '12';
                case 'Saturday':
                    return '13';
            }
        };
    const getEquipmentConfig = (equipmentId) => {
        props.getConfigByEquipementId(equipmentId);
        props.getEmailNotifConfigByEquipementId(equipmentId);
    };
    const resetVehiculeSelection = () => {
        Object.keys(props.checkboxRefs.toJS().current).forEach((key) => {
            props.checkboxRefs.toJS().current[key].state.checked = false;
        });
    };
    const steps = [
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Config',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Target',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Finish',
        }),
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [allSelected, setAllSelected] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [selectedTimeStart, setSelectedTimeStart] = useState('07:00');
    const [selectedTimeEnd, setSelectedTimeEnd] = useState('07:00');
    const [fuelType, setFuelType] = useState('gasoline');
    const [checkRalProlong, setCheckRalProlong] = useState(false);
    const [ralProlong, setRalProlong] = useState(60);
    const [ralProlongError, setRalProlongError] = useState(false);
    const [checkEngineCutoff, setCheckEngineCutoff] = useState(false);
    const [litersPerHour, setLitersPerHour] = useState(0);
    const [horraireValue, setHorraireValue] = useState(0);
    const [checkSpeedDetection, setCheckSpeedDetection] = useState(false);
    const [maximumSpeed, setMaximumSpeed] = useState(0);
    const [preAlertSpeed, setPreAlertSpeed] = useState(0);
    const [preAlertSpeedCheck, setPreAlertSpeedCheck] = useState(false);
    const [
        gForceAccelerationDecelerationThreshold,
        setGForceAccelerationDecelerationThreshold,
    ] = useState(0);
    const [impact, setImpact] = useState(0);
    const [seriesBar, setSeriesBar] = useState([
        {
            name: props.intl.formatMessage({
                id: 'components.Modals.Form.Config.Accelerometre.Accel',
            }),
            data: [0, 0, 0, 0],
        },
        {
            name: props.intl.formatMessage({
                id: 'components.Modals.Form.Config.Accelerometre.Decel',
            }),
            data: [0, 0, 0, 0],
        },
    ]);
    const [wakeUpIntervalMinutes, setWakeUpIntervalMinutes] = useState(15);
    const [lpmCheck, setLpmCheck] = useState(false);
    const [active, setActive] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [distanceThreshold, setDistanceThreshold] = useState(0);
    useEffect(
        () => {
            if (!props.UnauthorizedMoveRecords.get('schedules')) return;
            const schedulestoSet = [];
            props.UnauthorizedMoveRecords.get('schedules').map((item) => {
                schedulestoSet.push([
                    `${defaultdate + dateCalendarAdapter(item.get('day'))}T${item.get(
                        'startRange'
                    )}`,
                    `${defaultdate + dateCalendarAdapter(item.get('day'))}T${item.get('endRange')}`,
                ]);
            });
            setActive(props.UnauthorizedMoveRecords.get('active'));
            setSchedules(schedulestoSet);
            setDistanceThreshold(props.UnauthorizedMoveRecords.get('distanceThreshold'));
        },
        [props.UnauthorizedMoveRecords]
    );
    useEffect(
        () => {
            if (!props.configRecords.get('lowPowerMode')) return;
            if (!props.configRecords.get('lowPowerMode').get('isEnabled')) return;
            if (!props.configRecords.get('lowPowerMode').get('wakeUpIntervalMinutes')) return;
            setLpmCheck(props.configRecords.get('lowPowerMode').get('isEnabled'));
            setWakeUpIntervalMinutes(
                props.configRecords.get('lowPowerMode').get('wakeUpIntervalMinutes')
            );
        },
        [props.configRecords]
    );
    useEffect(
        () => {
            if (!props.configRecords.get('accelerationDetection')) return;
            if (!props.configRecords.get('accelerationDetection').get('calibrationState')) return;
            if (!props.configRecords.get('accelerationDetection').get('calibrationStep')) return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('gForceAccelerationDecelerationThreshold')
            )
                return;
            if (!props.configRecords.get('accelerationDetection').get('impactThreshold')) return;
            if (!props.configRecords.get('accelerationDetection').get('sidesAccelerationThreshold'))
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('forwardAccelerationThreshold')
            )
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('reverseAccelerationThreshold')
            )
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('forwardDecelerationThreshold')
            )
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('reverseDecelerationThreshold')
            )
                return;
            setGForceAccelerationDecelerationThreshold(
                props.configRecords
                    .get('accelerationDetection')
                    .get('gForceAccelerationDecelerationThreshold')
                    ? parseFloat(
                          props.configRecords
                              .get('accelerationDetection')
                              .get('gForceAccelerationDecelerationThreshold')
                      )
                    : 0
            );
            setImpact(
                props.configRecords.get('accelerationDetection').get('impactThreshold')
                    ? parseFloat(
                          props.configRecords.get('accelerationDetection').get('impactThreshold')
                      )
                    : 0
            );
            setSeriesBar([
                {
                    name: props.intl.formatMessage({
                        id: 'components.Modals.Form.Config.Accelerometre.Accel',
                    }),
                    data: [
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('forwardAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('forwardAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('reverseAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('reverseAccelerationThreshold')
                              )
                            : 0,
                    ],
                },
                {
                    name: props.intl.formatMessage({
                        id: 'components.Modals.Form.Config.Accelerometre.Decel',
                    }),
                    data: [
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('forwardDecelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('forwardDecelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('reverseDecelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('reverseDecelerationThreshold')
                              )
                            : 0,
                    ],
                },
            ]);
        },
        [props.configRecords]
    );
    useEffect(
        () => {
            console.log('fuelType', fuelType);
        },
        [fuelType]
    );

    const handleHorraireValue = (e) => {
        setHorraireValue(e.target.value);
    };
    const handlefuelTypeChange = (event) => {
        setFuelType(event.target.value);
    };
    const handleTimeChangeStart = (event) => {
        setSelectedTimeStart(event.target.value);
    };
    const handleTimeChangeEnd = (event) => {
        setSelectedTimeEnd(event.target.value);
    };
    useEffect(
        () => {
            if (ralProlongError) {
                setTimeout(() => {
                    setRalProlongError(false);
                }, 3000);
            }
        },
        [ralProlongError]
    );
    useEffect(
        () => {
            if (props.configRecords.size === 0) return;
            const speedDetection = props.configRecords.get('speedDetection');
            const preAlertMaximumSpeed = speedDetection.get('preAlertMaximumSpeed');
            const additionalData = props.configRecords.get('additionalData');
            const engineCutoff = props.configRecords.get('engineCutoff');
            let maximumSpeed = speedDetection.get('maximumSpeed');

            const checkSpeedDetection = speedDetection.get('isSpeedThresholdActivated');
            (maximumSpeed =
                maximumSpeed >= 0 && maximumSpeed < 1000
                    ? Math.floor(props.i18nDistanceUnit(maximumSpeed))
                    : 0),
                setMaximumSpeed(maximumSpeed);
            setCheckSpeedDetection(checkSpeedDetection);
            setCheckRalProlong(engineCutoff.get('activeIdleEvent'));
            setFuelType(additionalData.get('fuel_type') || 'gasoline');
            setCheckEngineCutoff(engineCutoff.get('cutoffCutEngine'));
            setLitersPerHour(
                props.i18nVolumeUnit(additionalData.get('liters_per_hour')) ||
                    (props.isImperial() ? 1 : 4)
            );
            setPreAlertSpeed(preAlertMaximumSpeed);
            setPreAlertSpeedCheck(speedDetection.get('isPreAlertSpeedActivated'));
        },
        [props.configRecords]
    );

    const handleLitersChange = (e) => {
        setLitersPerHour(e.target.value);
    };
    const handleCheckRalProlong = (e) => {
        setCheckRalProlong(e);
    };
    const handleRalProlong = (e) => {
        if (e.target.value < 60 && e.target.value > 9999) {
            setRalProlongError(true);
            return;
        }
        setRalProlong(e.target.value);
    };
    const handleMaximumSpeed = (e) => {
        setMaximumSpeed(e.target.value);
    };
    const handlePreAlertSpeed = (e) => {
        setPreAlertSpeed(e.target.value);
    };
    const handleCheckSpeedDetection = (e) => {
        if(!e) {
            setPreAlertSpeedCheck(false);
            setPreAlertSpeed(0);
        }
        setCheckSpeedDetection(e);
    };
    const handleCheckPreAlertSpeed = (e) => {
        if (!checkSpeedDetection) return;
        setPreAlertSpeedCheck(e);
    };
    const copyConfigNotif = (placement, status) => {
        console.log(status);
        if (status == 204) {
            api.success({
                message: `Success`,
                description: `Success`,
                placement,
            });
        } else {
            api.warning({
                message: `!Oops`,
                description: `Error copying configuration`,
                placement,
            });
        }
    };
    const onClickCopy = () => {
        setActiveStep(1);
    };

    return (
        <Grid
            container
            sx={{
                mt: 5,
                mb: 5,
                border: `1px solid ${colors.green61}`,
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                padding: '20px',
                height: '90%',
                margin: '32px',
                width: '91%',
                overflowY: 'scroll',
            }}
        >
            <Grid item xs={12}>
                <h5 style={{ textAlign: 'left' }}>Configuration</h5>
            </Grid>
            {contextHolder}
            {activeStep > 0 && (
                <Grid
                    item
                    xs={2}
                    style={{
                        justifyContent: 'start',
                        display: 'flex',
                        paddingLeft: '30px',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                            margin: '0.5em',
                        }}
                        onClick={() => {
                            setActiveStep(activeStep - 1);
                            if (activeStep === 0) {
                                resetVehiculeSelection();
                            }
                        }}
                    >
                        <span>Back</span>
                    </Button>
                </Grid>
            )}
            {activeStep > 0 && (
                <Grid item xs={8}>
                    <Stepper
                        activeStep={activeStep}
                        sx={{ justifyContent: 'center', width: '115%' }}
                        connector={<ApStepperSeperator />}
                    >
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconComponent={() =>
                                            ApReservationStepIcon(index, activeStep)
                                        }
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
            )}
            <Grid
                item
                xs={2}
                mb={5}
                style={{ justifyContent: 'end', display: 'flex', paddingRight: '30px' }}
            >
                {activeStep === steps.length - 2 ? (
                    <Button
                        variant="outlined"
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                        }}
                        onClick={() => {
                            setActiveStep(2);
                        }}
                    >
                        <span>Next</span>
                    </Button>
                ) : activeStep === steps.length - 1 ? (
                    <React.Fragment>
                        <Button
                            variant="outlined"
                            sx={{
                                height: 'fit-content',
                                textTransform: 'none',
                                backgroundColor: '#4F9C20',
                                color: 'white',
                                borderColor: '#4F9C20',
                                '&:hover': {
                                    backgroundColor: '#3E7C16',
                                    borderColor: '#3E7C16',
                                },
                            }}
                            onClick={() => {
                                props
                                    .copyConfiguration(
                                        props.vehicleToCopy.vehicleId,
                                        props.copyTargetVehicles.toJS(),
                                        props.configCopyEco,
                                        props.configCopyNotif,
                                        props.configCopyUnauth,
                                        props.configCopyLpm,
                                        props.configCopyAccel
                                    )
                                    .then((response) => {
                                        copyConfigNotif('top', response.status);
                                    })
                                    .catch((error) => {
                                        console.error('Error copying configuration', error);
                                    });
                            }}
                        >
                            <span>Finish</span>
                        </Button>
                    </React.Fragment>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            {activeStep === steps.length - 1 ? (
                <Grid container className="finishContainer">
                    <FinishCopyComponent />
                </Grid>
            ) : (
                <Grid
                    container
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <Grid item xs={3}>
                        <VehiculeListConfig
                            step={activeStep}
                            onClickConfig={getEquipmentConfig}
                            onClickCopy={onClickCopy}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid
                                xs={12}
                                className="vehiculeItem"
                                style={{
                                    fontSize: '14px',
                                    fontFamily: 'inter',
                                    paddingBottom: '20px',
                                    fontWeight: '900',
                                    color: '#101828',
                                    paddingLeft: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                {activeStep === 1 && (
                                    <div>
                                        <Checkbox
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAllSelected(true);
                                                } else {
                                                    setAllSelected(false);
                                                }
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Select All</span>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <EconomieConfigComponent
                                    selectedTimeStart={selectedTimeStart}
                                    selectedTimeEnd={selectedTimeEnd}
                                    fuelType={fuelType}
                                    checkRalProlong={checkRalProlong}
                                    ralProlong={ralProlong}
                                    ralProlongError={ralProlongError}
                                    checkEngineCutoff={checkEngineCutoff}
                                    litersPerHour={litersPerHour}
                                    horraireValue={horraireValue}
                                    checkSpeedDetection={checkSpeedDetection}
                                    maximumSpeed={maximumSpeed}
                                    preAlertSpeed={preAlertSpeed}
                                    preAlertSpeedCheck={preAlertSpeedCheck}
                                    handleHorraireValue={handleHorraireValue}
                                    handlefuelTypeChange={handlefuelTypeChange}
                                    handleTimeChangeStart={handleTimeChangeStart}
                                    handleTimeChangeEnd={handleTimeChangeEnd}
                                    handleLitersChange={handleLitersChange}
                                    handleCheckRalProlong={handleCheckRalProlong}
                                    handleRalProlong={handleRalProlong}
                                    handleMaximumSpeed={handleMaximumSpeed}
                                    handlePreAlertSpeed={handlePreAlertSpeed}
                                    handleCheckSpeedDetection={handleCheckSpeedDetection}
                                    handleCheckPreAlertSpeed={handleCheckPreAlertSpeed}
                                    checked={allSelected}
                                    step={activeStep}
                                />
                                <LowPowerModeSettings
                                    wakeUpIntervalMinutes={wakeUpIntervalMinutes}
                                    setWakeUpIntervalMinutes={setWakeUpIntervalMinutes}
                                    lpmCheck={lpmCheck}
                                    setLpmCheck={setLpmCheck}
                                    checked={allSelected}
                                    step={activeStep}
                                />
                            </Grid>

                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <AccelerometreSettings
                                    gForceAccelerationDecelerationThreshold={
                                        gForceAccelerationDecelerationThreshold
                                    }
                                    impact={impact}
                                    seriesBar={seriesBar}
                                    setGForceAccelerationDecelerationThreshold={
                                        setGForceAccelerationDecelerationThreshold
                                    }
                                    setImpact={setImpact}
                                    setSeriesBar={setSeriesBar}
                                    checked={allSelected}
                                    step={activeStep}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <UnauthorizedMoveConfigBox
                                    active={active}
                                    setActive={setActive}
                                    schedules={schedules}
                                    setSchedules={setSchedules}
                                    distanceThreshold={distanceThreshold}
                                    setDistanceThreshold={setDistanceThreshold}
                                    checked={allSelected}
                                    step={activeStep}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <NotificationConfig checked={allSelected} step={activeStep} />
                            </Grid>
                            {activeStep === 0 && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        maxWidth: '97.3%',
                                        margin: '0.5em',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        sx={{
                                            height: 'fit-content',
                                            textTransform: 'none',
                                            backgroundColor: '#4F9C20',
                                            color: 'white',
                                            borderColor: '#4F9C20',
                                            '&:hover': {
                                                backgroundColor: '#3E7C16',
                                                borderColor: '#3E7C16',
                                            },
                                        }}
                                    >
                                        Sauvegarder
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
const mapStateToProps: Object = createStructuredSelector({
    configCopyEco: selectConfigCopyEco(),
    configCopyNotif: selectConfigCopyNotif(),
    configCopyUnauth: selectConfigCopyUnauth(),
    configCopyLpm: selectConfigCopyLpm(),
    configCopyAccel: selectConfigCopyAccel(),
    copyTargetVehicles: selectCopyTargetVehicles(),
    vehicleToCopy: selectVehicleToCopy(),
    checkboxRefs: selectVehiculeRefs(),
    copyConfigError: selectConfigCopyError(),
    configRecords: selectConfigRecords(),
    UnauthorizedMoveRecords: selectUnauthorizedMoveRecords(),

});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getConfigByEquipementId,
            getEmailNotifConfigByEquipementId,
            copyConfiguration,
            i18nDistanceUnit,
            i18nVolumeUnit,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Configuration))
);
