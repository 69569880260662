// @flow

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { InputGroup, Form } from 'react-bootstrap';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import Grid from '@mui/material/Grid';
import 'bootstrap/dist/css/bootstrap.min.css';
import colors from 'styles/colors';
import TimeGridScheduler from 'components/TimeGridScheduler';
import { Switch } from 'antd';
import './styles.scss';

// Selectors
import { selectLocale } from 'services/Language/selectors';
// Thunks
import {
    getUnauthorizedMoveConfigByEquipementId,
    setUnauthorizedMoveCopy,
} from 'services/Config/thunks';

type Props = {
    locale: ?string,
    UnauthorizedMoveRecords: *,
    handleChange: *,
    equipmentPopout: *,
    getUnauthorizedMoveConfigByEquipementId: () => void,
};

const UnauthorizedMoveConfigBox = (props: Props) => {
    const [detailed, setDetailed] = useState(true);
    const [buttonChecked, setButtonChecked] = useState(false);

    

    const getChangedItems = () => {
        return { active :props.active,  schedules :props.schedules, distanceThreshold: props.distanceThreshold };
    };

    const handleDepNonAutoriseConfigBox = async (item) => {
        props.setSchedules(item);
        await props.handleChange(getChangedItems());
    };
    const handleUnauthorisedMoveChange = (e) => {
        if (e.target.checked) {
            props.setUnauthorizedMoveCopy(true);
            setButtonChecked(true);

        } else {
            props.setUnauthorizedMoveCopy(false);
            setButtonChecked(false);

        }
    };
    useEffect(
        () => {
            if (props.checked) {
                props.setUnauthorizedMoveCopy(true);
                setButtonChecked(true);
            } else {
                setButtonChecked(false);
                props.setUnauthorizedMoveCopy(false);
            }
        },
        [props.checked]
    );
    return (
        <Grid
            container
            sx={{
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                height: detailed ? '50vh' : 'fit-content',
                overflowY: 'scroll',
                borderRadius: '7px',

            }}
        >
            <Grid xs={8}>
                {props.step === 1 && (
                    <ConfigCustomCheckbox
                        checked={buttonChecked}
                        onChange={(e) => handleUnauthorisedMoveChange(e)}
                    />
                )}
                <span className="economieHeader">
                    <FormattedMessage id={'components.Modals.Form.Config.DeplacementNoNAutorise'} />
                </span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid item xs={12} sx={{ mb: '15px' }}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>
            {detailed ? (
                <Grid container>
                    <Grid xs={12}>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label>
                                <div style={{ color: colors.gray46 }}>
                                    <FormattedMessage
                                        id={
                                            'components.Modals.Form.Config.DeplacementNoNAutorise.info'
                                        }
                                    />
                                </div>
                            </Form.Label>
                        </Form.Group>
                    </Grid>
                    <Grid xs={7} style={{ paddingBottom: 15 }}>
                        <div>
                            <Switch
                                onChange={async (checked) => {
                                    props.setActive(checked);
                                    await props.handleChange(getChangedItems());
                                }}
                                checked={ props.active}
                            />
                            <span style={{ paddingLeft: 10 }}>
                                <FormattedMessage
                                    id={
                                        'components.Modals.Form.Config.DeplacementNoNAutorise.plageHoraire.switch'
                                    }
                                />
                            </span>
                        </div>
                    </Grid>
                    <Grid xs={5}>
                        { props.active ? (
                            <InputGroup>
                                <Form.Control
                                    size="sm"
                                    type="number"
                                    onChange={async (e) => {
                                        const value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 5);
                                            props.setDistanceThreshold(value);
                                        await props.handleChange(getChangedItems());
                                    }}
                                    placeholder=""
                                    value={ props.distanceThreshold}
                                />
                                <InputGroup.Prepend style={{ height: 31 }}>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FormattedMessage
                                            id={
                                                'components.Modals.Form.Config.DeplacementNoNAutorise.metres'
                                            }
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        ) : null}
                    </Grid>
                    { props.active ? (
                        <Grid xs={12} style={{ paddingBottom: 15 }}>
                            <TimeGridScheduler
                                rangeStrings={ props.schedules}
                                locale={props.locale}
                                handleChange={handleDepNonAutoriseConfigBox}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
};

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUnauthorizedMoveConfigByEquipementId,
            setUnauthorizedMoveCopy,
        },
        dispatch
    );

// EXPORTS
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UnauthorizedMoveConfigBox)
);
