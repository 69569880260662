import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { parseJWT } from 'helpers';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import Grid from '@mui/material/Grid';
import { setNotificationCopy } from 'services/Config/thunks';
// Selectors
import { selectEmailsRecords } from 'services/Config/selectors';
import { selectUser } from 'services/Authentication/selectors';
//THUNKS
import { getEmailsByBranchId } from 'services/Config/thunks';
function NotificationConfig(props) {
    const [detailed, setDetailed] = useState(true);
    const [emailList, setEmailList] = useState([]);
    const [buttonChecked, setButtonChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            props.setNotificationCopy(true);
            setButtonChecked(true);

        } else {
            props.setNotificationCopy(false);
            setButtonChecked(false);

        }
    };
    useEffect(
        () => {
            if (!props.user.get('token')) return;
            const jwt = parseJWT(props.user.get('token'));
            const branchId = jwt['https://geothentic.com/branch_id'];
            props.getEmailsByBranchId(branchId);
        },
        [props.user]
    );
    useEffect(
        () => {
            if (props.emails && props.emails.size > 0) {
                const emails = props.emails.toJS().map((email) => email.emailAddress);
                setEmailList(emails);
            }
        },
        [props.emails]
    );
    useEffect(
        () => {
            if (props.checked) {
                setButtonChecked(true);
                props.setNotificationCopy(true);
            } else {
                setButtonChecked(false);
                props.setNotificationCopy(false);
            }
        },
        [props.checked]
    );
    return (
        <Grid
            container
            sx={{
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                borderRadius: '7px',
                overflowY: 'scroll',
                height: detailed ? '50vh' : 'fit-content',
            }}
        >
            <Grid xs={8}>
                {props.step === 1 && (
                    <ConfigCustomCheckbox
                        checked={buttonChecked}
                        onChange={(e) => handleCheckboxChange(e)}
                    />
                )}
                <span className={'economieHeader'}>Notification par courriel</span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid xs={12}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>
            {detailed ? (
                <React.Fragment>
                    {emailList.map((email, index) => (
                        <Grid container item xs={12} key={index} alignItems="center">
                            <Grid item marginRight={1}>
                                <ConfigCustomCheckbox />
                            </Grid>
                            <Grid item>
                                <span>{email}</span>
                            </Grid>
                        </Grid>
                    ))}
                </React.Fragment>
            ) : null}
        </Grid>
    );
}
const mapStateToProps = createStructuredSelector({
    emails: selectEmailsRecords(),
    user: selectUser(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators({ getEmailsByBranchId, setNotificationCopy }, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(NotificationConfig));
